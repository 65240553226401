import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {getCode, getCodeLabel, getCodeOptions, toDate} from "@core/utils/filter";

export default function stockUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  let tableColumns = [
     { key: 'id', label: 'ID', sortable: true },
    { key: 'code', label: '69码'},
    { key: 'name', label: '商品名称'},
    { key: 'production_date', label: '生产日期'},
    { key: 'warestatus', label: '库存状态'},
    { key: 'specification', label: '规格'},
    { key: 'productUnit', label: '单位',  },
    { key: 'qty', label: '计划数量'},
    { key: 'useQty', label: '本次出库'},
    { key: 'usedQty', label: '已出库'},
    { key: 'outboundTrueQty', label: '剩余数量'},
    { key: 'memo', label: '备注'},

  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref('id')
  const isSortDirDesc = ref(true)
  //getCode("companyStock",61)

  const qty = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit], () => {
    refetchData()
  })

  const searchALlList = (ctx, callback) => {

    store
      .dispatch('stock/searchStockOutboundOutBound', {
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        allotOutboundId:config.allotOutboundId,
        categoryId:config.categoryId,
        outboundId:config.outboundId,
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '库存列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  return {
    searchALlList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,


    refetchData,
    // Extra Filters
  }
}
